import request from '@/utils/request';

const PATH = '/api/auth';

// 로그인
export const userLogin = (data: any) => request({
  url: `${PATH}/userLogin`,
  method: 'POST',
  data,
});

// 로그아웃
export const userLogout = () => request({
  url: `${PATH}/userLogout`,
  method: 'POST',
});

// 첨부파일 업로드
export const uploadThumbnail = (data: FormData) => request({
  url: `${PATH}/summerUploadThumbnail`,
  method: 'POST',
  data,
})

// 그룹 참여(초대 코드)
export const inviteGroup = (data: any) => request({
  url: '/apin/v2/groups/join',
  method: 'POST',
  params: data,
})

// 유저 프로필 업데이트
export const updateProfile = (data: any) => request({
  url: `${PATH}/updateProfile`,
  method: 'POST',
  data,
})

// 유저 비밀번호 변경
export const updatePassword = (data: any) => request({
  url: `${PATH}/changePassword`,
  method: 'POST',
  data,
})

// 유저 닉네임 중복검사
export const regValPenname = (data: any) => request({
  url: `${PATH}/regValPenname`,
  method: 'POST',
  data,
})

// 유저 프로필 이미지 업데이트
export const updateUserAvatar = (data: any) => request({
  url: `${PATH}/updateUserAvatar`,
  method: 'POST',
  data,
})

// 멤버십 코드 등록
export const registerLicense = (data: any) => request({
  url: `${PATH}/licenseRegistration`,
  method: 'POST',
  data,
})

// 소셜 로그인
export const registerAccount = (data: any) => request({
  url: `${PATH}/registerAccount`,
  method: 'POST',
  data,
})

// 약관 동의
export const regValAgree = (data: any) => request({
  url: `${PATH}/regValAgree`,
  method: 'POST',
  data,
})

// 유저 정보 입력
export const regValUserInfo = (data: any) => request({
  url: `${PATH}/regValUserInfo`,
  method: 'POST',
  data,
})

// 유저 정보 수정
export const registerUserInfo = (data: any) => request({
  url: `${PATH}/registerUserInfo`,
  method: 'POST',
  data,
})

// 패스워드 찾기
export const findPassword = (data: any) => request({
  url: `${PATH}/findPassword`,
  method: 'POST',
  data,
})

// 패스워드 찾기 링크 들어올때 유저정보
export const getUserFromEmail = (code: any, user_email: any) => request({
  url: `${PATH}/userFromEmail`,
  method: 'POST',
  params: {
    code,
    user_email,
  },
})

// 패스워드 찾기 => 패스워드 변경
export const resetNewPassword = (data: any) => request({
  url: `${PATH}/resetNewPassword`,
  method: 'POST',
  data,
})
